












































































































import { Vue, Component } from 'vue-property-decorator';
import { ClientApp, ClientAppSetting } from '@/models/client/models';
import { DeviceRelation } from '@/models/data/models';
import { BaseListColumn, ListModelField } from '@/models/core/base';
import { Device } from '@/models/device/models';
import { monitoringRouteName } from '../app';
import { PromiseConcurrencyLimiter } from '@/util/async';
import ConfigSelector from '@/apps/data/components/ConfigSelector.vue';
import { globalStore } from '@/store/modules/global';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { isEqual } from 'lodash';
import { deepCopy } from '@/util/util';
import { DashboardConfig, MonitoringDashboardMode } from '../interfaces';
import { Filter } from '@/api/ApiClientV2';
import { Location } from 'vue-router';
import { studyRouteName } from '@/apps/study/app';

interface Row {
  id: string;
  name?: string;
  role: string;
  icon: string;
  remove: string;
}

@Component({
  components: {
    ConfigSelector,
  },
  mixins: [BeforeLeaveGuard],
})
export default class MonitoringSettings extends Vue {
  DeviceRelation = DeviceRelation;
  deviceRelationSettings: ClientAppSetting | null = null;
  deviceRelations: string[] = [];
  originalDeviceRelations: string[] = [];
  mode: MonitoringDashboardMode = 'device';
  loaded = false;
  studyAppHandle = '';

  draggingRow = null;
  draggingRowIndex = 0;

  deviceInfos: { [id: string]: { name?: string; role: string } } = {};

  get filter(): Filter {
    // explicitly set to 'unknown' to not show anything
    let role = 'unknown';
    if (this.mode === 'gateway') {
      role = 'gateway';
    }
    return {
      application: globalStore.selection.application?.id || 'unknown', // explicitly set to 'unknown' to not show anything
      order_by: 'device_name_asc',
      role,
    };
  }

  get columns(): BaseListColumn[] {
    const listFields: ListModelField[] = [
      {
        key: 'device_name',
        sortable: true,
      },
      {
        key: 'device_device_id',
      },
      {
        key: 'role',
      },
      {
        key: 'last_seen',
      },
    ];
    return DeviceRelation.defaultColumns(DeviceRelation.langPath, listFields);
  }

  get tableData(): Row[] {
    const data = this.deviceRelations.map(id => {
      return {
        id,
        name: this.deviceInfos[id]?.name,
        role: this.deviceInfos[id]?.role,
        icon: 'mdi-reorder-horizontal',
        remove: 'mdi-close',
      };
    });
    return data;
  }

  get detailRouteName(): string {
    return monitoringRouteName('dashboard-detail');
  }

  get shouldWarnOnLeave(): boolean {
    return !isEqual(this.deviceRelations, this.originalDeviceRelations);
  }

  get showDeviceList(): boolean {
    return this.mode === 'device' || this.mode === 'gateway';
  }

  get studyAppRoute(): Location {
    return {
      name: studyRouteName('clinic.patient-list'),
      params: {
        app_handle: this.studyAppHandle,
      },
    };
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(showLoading = true): Promise<void> {
    const loading = showLoading ? this.$buefy.loading.open({}) : undefined;
    try {
      // fetch client app settings and keep in store
      await globalStore.fetchClientAppSettings();
      const configSettings = globalStore.clientAppSetting('config');
      if (!configSettings) {
        throw new Error('Client app setting "config" missing.');
      }
      const config: DashboardConfig = configSettings.value;
      this.mode = config?.mode ?? this.mode;
      this.studyAppHandle = config.studyClientAppHandle ?? '';

      const deviceRelationSettings =
        globalStore.clientAppSetting('device_relations');
      if (!deviceRelationSettings) {
        throw new Error('Client app setting "device_relations" missing.');
      }
      this.deviceRelationSettings = deviceRelationSettings;
      this.deviceRelations = deviceRelationSettings.value.device_relations;
      this.originalDeviceRelations = deepCopy(this.deviceRelations);
      const limiter = new PromiseConcurrencyLimiter();
      for (const r of this.deviceRelations) {
        await limiter.add(
          this.getDeviceInfo(r).then(info => {
            if (info) {
              this.deviceInfos[r] = info;
            }
          }),
        );
      }
      await limiter.awaitAllAndRethrow();
      this.loaded = true;
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    if (loading) {
      loading.close();
    }
  }

  async getDeviceInfo(
    deviceRelationId: string,
  ): Promise<{ name?: string; role: string } | void> {
    try {
      const deviceRelation = await this.$apiv2.get<DeviceRelation>(
        DeviceRelation,
        deviceRelationId,
      );
      const device = await this.$apiv2.get<Device>(
        Device,
        deviceRelation.device,
      );
      return {
        name: device.name,
        role: deviceRelation.role,
      };
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  async setSelectedDeviceRelations(deviceRelations: string[]): Promise<void> {
    this.deviceRelations = deviceRelations;
    await this.getMissingdeviceInfos(this.deviceRelations);
  }

  async getMissingdeviceInfos(deviceRelations: string[]): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const limiter = new PromiseConcurrencyLimiter();
      for (const r of deviceRelations) {
        if (this.deviceInfos[r] === undefined) {
          await limiter.add(
            this.getDeviceInfo(r).then(info => {
              if (info) {
                this.deviceInfos = {
                  ...this.deviceInfos,
                  [r]: info,
                };
              }
            }),
          );
        }
      }
      await limiter.awaitAllAndRethrow();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async save(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      await ClientApp.setSetting(
        this.$apiv2,
        this.$store.getters['global/selectedClientApp'].id,
        'device_relations',
        {
          device_relations: this.deviceRelations,
        },
        undefined,
        this.deviceRelationSettings?.object_seq,
      );
      this.originalDeviceRelations = deepCopy(this.deviceRelations);
      await this.init(false);
      this.$buefy.toast.open({
        message: this.$tc('common.saveSuccess'),
        type: 'is-success',
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  dragstart(payload: any): void {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }

  dragover(payload: any): void {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }

  dragleave(payload: any): void {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }

  drop(payload: any): void {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;
    this.deviceRelations = this.reorderArray(
      this.deviceRelations,
      this.draggingRowIndex,
      droppedOnRowIndex,
    );
  }

  reorderArray(array: any[], from: number, to: number): any[] {
    const element = array[from];
    array.splice(from, 1);
    array.splice(to, 0, element);
    return array.slice();
  }

  confirmRemove(row: Row): void {
    console.log('row', row);
    this.$buefy.dialog.confirm({
      message: this.$tc('common.confirmRemove'),
      onConfirm: () => {
        const index = this.deviceRelations.findIndex(r => r === row.id);
        this.deviceRelations.splice(index, 1);
      },
    });
  }
}
